// @flow
import React, { useState, memo, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import {
  CircularProgress,
  Button,
  TextField,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Block from '@material-ui/icons/Block';
import { useMutation } from '@apollo/client';
import tracking, { dataCreators } from '@dt/analytics';
import { palette } from '@dt/theme';
import policy_violations from '@dt/graphql-support/horizon/policy_violations';
import { useQuery } from '@apollo/client';
import Text from './Text';

import type {
  PolicyViolationsPatchMutation,
  PolicyViolationsPatchMutationVariables,
} from '@dt/graphql-support/types/PolicyViolationsPatchMutation';
import type { PolicyViolationGetVersion2Query } from '@dt/graphql-support/types';

type Props = {|
  +policy_violation_id: string,
|};

/*
 * Allows the user to close a policy violation with provided defaults or with a custom reason.
 *
 * @param policy_violation_id - Used with the graphql patch when updating the policy violation
 *
 * @example
 *     <PolicyViolationWontFixButton policy_violation_id={policy_violation.id} />
 */
const PolicyViolationWontFixButtonComponent = function PolicyViolationWontFixButton({
  policy_violation_id,
}: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [givenReason, setGivenReason] = useState('');

  const [
    patchPolicyViolation,
    { loading: patchPolicyViolationLoading, error: patchPolicyViolationError },
  ] = useMutation<
    PolicyViolationsPatchMutation,
    PolicyViolationsPatchMutationVariables,
  >(policy_violations.patch);
  const {
    data: policyViolationsGetData,
    refetch: policyViolationsGetRefetch,
  } = useQuery<PolicyViolationGetVersion2Query, _>(policy_violations.v2_get, {
    variables: {
      id: policy_violation_id,
    },
  });

  // When an error occurs, even if we didn't open the dialog, open the dialog to show the error.
  useEffect(() => {
    if (!patchPolicyViolationError) {
      return;
    }
    setIsDialogOpen(true);
  }, [patchPolicyViolationError]);

  // Event Handlers.
  const handleMenuOnClose = () => {
    // Close menu.
    setIsMenuOpen(false);
    setAnchorEl(null);
  };
  const handleButtonClick = (e: SyntheticEvent<HTMLElement>) => {
    setIsMenuOpen(true);
    setAnchorEl(e.currentTarget);
  };
  const handleOnSubmit = async (reason: string) => {
    // Set given reason, could be a predefined reason.
    setGivenReason(reason);

    // Close policy violation.
    const { errors } = await patchPolicyViolation({
      variables: {
        id: policy_violation_id,
        body: {
          exception_type: 'WONT_FIX',
          exception_explanation: reason,
        },
      },
    });

    if (!errors) {
      // Success.
      // Track when policy violations are closed.
      tracking(dataCreators.policyViolationExceptionChange(reason));

      // Invalidate policy violation cache.
      policyViolationsGetRefetch();

      return true;
    } else {
      // Failure
      return false;
    }
  };

  const isPolicyViolationOpen =
    policyViolationsGetData?.policy_violation_details_v2.status === 'OPEN';

  // Occurs when the user closes a policy violation with a predefined response through the menu.
  const isMenuButtonLoading = !isDialogOpen && patchPolicyViolationLoading;

  return (
    <>
      <Tooltip
        title={!isPolicyViolationOpen ? 'Policy violation already closed' : ''}
      >
        {/* Span is used for Tooltip to register content area. */}
        <span>
          <Button
            variant="contained"
            size="small"
            onClick={handleButtonClick}
            disabled={!isPolicyViolationOpen || isMenuButtonLoading}
          >
            <Block style={{ marginRight: 4, fontSize: 14 }} />
            Close Violation{' '}
            {isMenuButtonLoading && (
              <CircularProgress style={{ marginLeft: 8 }} size={14} />
            )}
          </Button>
        </span>
      </Tooltip>
      <Menu
        disableScrollLock
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleMenuOnClose}
      >
        {[
          "Won't fix",
          'Asset is unauthenticated by design',
          'Compensating control in place',
          'Risk is accepted',
        ].map(reason => (
          <MenuItem
            key={reason}
            onClick={() => {
              handleOnSubmit(reason + '.');
              handleMenuOnClose();
            }}
          >
            {reason}
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            setIsDialogOpen(true);
            handleMenuOnClose();
          }}
        >
          Other
        </MenuItem>
      </Menu>

      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        PaperProps={{ style: { width: '100%' } }}
      >
        <DialogTitle>Close Violation</DialogTitle>
        <DialogContent>
          <TextField
            required
            onChange={(e: SyntheticInputEvent<HTMLInputElement>) =>
              setGivenReason(e.target.value)
            }
            value={givenReason}
            rows={3}
            fullWidth
            autoFocus
            margin="dense"
            label="Please provide an explanation"
            placeholder="Please provide an explanation"
            multiline={true}
          />
          {patchPolicyViolationError && (
            <div>
              <Text variant="body" color={palette.red30}>
                An error occurred while updating the policy violation.
              </Text>
              <Text variant="body" color={palette.red30}>
                {patchPolicyViolationError.message}
              </Text>
              <Text variant="body" color={palette.red30}>
                Please try again later or contact us at support@datatheorem.com.
              </Text>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button key={1} onClick={() => setIsDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            key={2}
            onClick={() => {
              (async () => {
                const success = await handleOnSubmit(givenReason);
                setIsDialogOpen(!success);
              })();
            }}
            disabled={patchPolicyViolationLoading || givenReason.length <= 0}
          >
            Confirm{' '}
            {patchPolicyViolationLoading && (
              <CircularProgress style={{ marginLeft: 8 }} size={15} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const PolicyViolationWontFixButton = memo<Props>(
  PolicyViolationWontFixButtonComponent,
);
