// @flow
import config from '@dt/config';
import qs from 'query-string';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';

import {
  type NetworkServicesList,
  type DomainNameList,
  type PolicyViolationList,
  type PolicyRuleList,
  type PolicyRuleTypeList,
  type RestfulAPIsList,
  type TlsScanResultList,
  type EventsList,
  type CertificateList,
  type CertificateChainList,
  type PaginatedResponse,
  type NetworkService,
  type WebApplicationList,
} from './types';

if (typeof config.horizonAPI !== 'string') {
  throw new Error('horizonAPI is required but not set in config');
}

const api = config.horizonAPI;
const version = 'public/v1';

export type NetworkServicesListResponse = {|
  +network_services: NetworkServicesList,
  +domain_names: DomainNameList,
  +policy_violations: PolicyViolationList,
  +policy_rules: PolicyRuleList,
  +policy_rule_types: PolicyRuleTypeList,
|};

export type NetworkServiceDetailsResponse = {|
  ...NetworkServicesListResponse,
  +certificates: CertificateList,
  +certificate_chains: CertificateChainList,
  +restful_apis: RestfulAPIsList,
  +tls_scan_results: TlsScanResultList,
  +events: EventsList,
  +web_applications: WebApplicationList,
|};

export async function list(params: {
  ...,
}): FetchResponse<PaginatedResponse<NetworkServicesListResponse>> {
  return fetch(
    `${api}/${version}/network_services?${qs.stringify(params)}`,
  ).then(parse);
}

export async function details(
  id: string,
): FetchResponse<NetworkServiceDetailsResponse> {
  return fetch(`${api}/${version}/network_services/${id}`).then(parse);
}

type CreateNetworkServiceParams = {|
  +domain_name: string,
  +port: number,
  +application_layer_protocol: 'HTTP' | 'UNKNOWN',
  +is_tls_encrypted: boolean,
|};

export async function create(
  params: CreateNetworkServiceParams,
): FetchResponse<NetworkService> {
  return fetch(`${api}/${version}/network_services`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
