// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';
import {
  type PaginatedResponse,
  type UsersList,
  type EventsList,
  type PolicyList,
  type PolicyRuleList,
  type PolicyRuleTypeList,
} from './types';

if (typeof config.horizonAPI !== 'string') {
  throw new Error('horizonAPI is required but not set in config');
}

const api = config.horizonAPI;
const version = '/public/v1';

export type AssetGroupOverview = {|
  restful_apis_count: number,
  api_operations_count: number,
  web_applications_count: number,
  network_services_count: number,
  cloud_resources_count: number,
|};

export type AssetGroup = {|
  id: string,
  date_created: string,
  name: string,
  description: string,
  attached_policy_id: string,
  created_by_user_id: string,
  asset_group_memberships_overview: AssetGroupOverview,
|};

export type AssetGroupList = $ReadOnlyArray<AssetGroup>;

export type AssetGroupListResponse = {|
  asset_groups: AssetGroupList,
  users: UsersList,
|};

export async function list(): FetchResponse<
  PaginatedResponse<AssetGroupListResponse>,
> {
  return fetch(`${api}/${version}/asset_groups`).then(parse);
}

export type AssetGroupDetailsResponse = {|
  asset_groups: AssetGroupList,
  users: UsersList,
  events: EventsList,
  policies: PolicyList,
  policy_rule: PolicyRuleList,
  policy_rule_types: PolicyRuleTypeList,
|};

export async function details(
  asset_group_id: string,
): FetchResponse<AssetGroupDetailsResponse> {
  return fetch(`${api}/${version}/asset_groups/${asset_group_id}`).then(parse);
}

type AssetGroupCreateParams = {|
  name: string,
  description: string,
  attached_policy_id: string,
|};

export async function create(
  params: AssetGroupCreateParams,
): FetchResponse<AssetGroupDetailsResponse> {
  return fetch(`${api}/${version}/asset_groups`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}

export async function remove(asset_group_id: string): Promise<boolean> {
  return fetch(`${api}/${version}/asset_groups/${asset_group_id}`, {
    method: 'DELETE',
  }).then(r => r.ok);
}

type AssetGroupPatchParams = {|
  name?: string,
  description?: string,
  attached_policy_id?: string,
|};

export async function patch(
  asset_group_id: string,
  params: AssetGroupPatchParams,
): FetchResponse<AssetGroupDetailsResponse> {
  return fetch(`${api}/${version}/asset_groups/${asset_group_id}`, {
    method: 'PATCH',
    body: JSON.stringify(params),
  }).then(parse);
}

export const AssetGroupMembershipEnum = {
  ASSET_SEARCH_MATCH: 'ASSET_SEARCH_MATCH',
  MANUAL: 'MANUAL',
};

export type AssetGroupMembershipEnumType = $Keys<
  typeof AssetGroupMembershipEnum,
>;

export type AssetGroupMembership = {|
  id: string,
  date_created: string,
  membership_kind: AssetGroupMembershipEnumType,
  asset_group_id: string,
  created_by_user_id: string,
  restful_api_id: null | string,
  web_application_id: null | string,
  cloud_resource_id: null | string,
  network_service_id: null | string,
|};

export type AssetGroupMembershipList = $ReadOnlyArray<AssetGroupMembership>;

export type AssetGroupMembershipResponse = {|
  asset_group_memberships: AssetGroupMembershipList,
|};

export type AssetGroupMembershipParams = {|
  cloud_resource_ids: Array<string>,
  network_service_ids: Array<string>,
  web_application_ids: Array<string>,
  restful_api_ids: Array<string>,
|};

export const memberships = {
  list: async (
    asset_group_id: string,
  ): FetchResponse<PaginatedResponse<AssetGroupDetailsResponse>> =>
    fetch(`${api}/${version}/asset_groups/${asset_group_id}/memberships`).then(
      parse,
    ),

  create: async (
    asset_group_id: string,
    memberships: AssetGroupMembershipParams,
  ): FetchResponse<AssetGroupMembershipResponse> =>
    fetch(`${api}/${version}/asset_groups/${asset_group_id}/memberships`, {
      method: 'POST',
      body: JSON.stringify(memberships),
    }).then(parse),
};

export type AssetsDiscoverySourceEnum =
  | 'ALL_SOURCES'
  | 'PUBLIC_INTERNET_ANALYZER';

export type ShadowAssetConfigurationParams = {
  should_add_assets_hosted_in_unknown_cloud_environment: boolean,
  should_add_assets_hosted_in_cloud_providers: ?Array<string>,
  should_add_assets_not_used_by_mobile_or_web_applications: boolean,
  should_add_assets_recently_discovered_via_source: ?AssetsDiscoverySourceEnum,
  should_add_cloud_resource_of_types: ?Array<string>,
  days_count_when_move_assets_out_of_group: ?number,
};

type ShadowAssetConfigurationResponse = {
  configuration: ShadowAssetConfigurationParams,
};

export const shadow = {
  memberships: {
    delete: async (membership_id: string): FetchResponse<null> =>
      fetch(
        `${api}/${version}/asset_groups/shadow/memberships/${membership_id}`,
        {
          method: 'DELETE',
        },
      ).then(parse),
  },
  configuration: {
    edit: async (
      configuration: ShadowAssetConfigurationParams,
    ): FetchResponse<ShadowAssetConfigurationResponse> =>
      fetch(`${api}/${version}/asset_groups/shadow/configuration`, {
        method: 'PUT',
        body: JSON.stringify({
          configuration,
        }),
      }).then(parse),
  },
};
