//@flow
import gql from 'graphql-tag';

const v2_get = gql`
  query MobileApplicationDetailsGetV2($id: ID) {
    mobile_app_details_v2(id: $id) {
      id
      mobile_secure_portal_url
    }
  }
`;

const get = gql`
  query MobileApplicationDetailsGetQuery($id: ID) {
    mobile_application_details(id: $id) {
      mobile_applications {
        id
        app_secure_id
        name
        platform
        icon_url
        most_recent_scan {
          end_date
          date_updated
          mobile_app_id
          start_date
          date_created
          app_version
          id
        }
      }
      restful_apis {
        id
        title
        base_url
        asset_type_name
        asset_type_icon_url
        date_created
        date_no_longer_accessible
        discovered_via
        api_operations {
          http_method
          path
          is_authenticated
          policy_violations {
            id
            status
            date_resolved
            violated_policy_rule {
              id
              relevance
            }
          }
          restful_api {
            id
            base_url
          }
        }
      }
      cloud_resources {
        id
        date_created
        name
        region
        resource_type
        asset_type_icon_url
        asset_type_name
        runtime
        status
        date_no_longer_accessible
        discovered_via
        hosted_on
        cloud_console_url
        url
        policy_violations {
          id
          status
          violated_policy_rule {
            id
            relevance
            policy_rule_type {
              id
              title
            }
          }
        }
      }
    }
  }
`;

export default {
  get,
  v2_get,
};
