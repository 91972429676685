//@flow
import React, { useState } from 'react';
import { Card, CardContent, Tab, Tabs } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from '@dt/theme';
import Text from './Text';

const withStyles = makeStyles(() => ({
  tabContainer: {
    overflowX: 'auto',
  },
  tabContainerContent: {
    padding: 0,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  tab: {
    maxWidth: 'inherit',
    color: palette.gray30,
  },
  selectedTab: {
    color: palette.brand35,
    borderBottom: `3px solid ${palette.brand35}`,
  },
}));

type Props = {|
  query: string,
  response: string,
|};
export default function QueryAndResponse({ query, response }: Props) {
  const classes = withStyles();
  const [activeTab, setActiveTab] = useState('query');

  return (
    <Card style={{ paddingTop: 0 }}>
      <CardContent style={{ paddingTop: 0 }}>
        <Box borderRadius={0} px={3} py={1} style={{ padding: 0 }}>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            indicatorColor="primary"
            style={{
              color: palette.brand35,
              borderBottom: `2px solid ${palette.gray30}`,
            }}
            aria-label="query and response details"
          >
            <Tab
              value="query"
              label="Query"
              className={classes.tab}
              classes={{
                selected: classes.selectedTab,
              }}
            />
            <Tab
              value="response"
              label="Response"
              className={classes.tab}
              classes={{
                selected: classes.selectedTab,
              }}
            />
          </Tabs>

          <div className={classes.tabContainer}>
            <Text variant="code" className={classes.tabContainerContent}>
              {activeTab === 'query'
                ? query
                  ? query
                  : 'Currently unavailable. Please check back later!'
                : null}
              {activeTab === 'response'
                ? response
                  ? response
                  : 'Currently unavailable. Please check back later!'
                : null}
            </Text>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
}
