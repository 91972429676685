//@flow
import React, { memo } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { type Severity } from '@material-ui/lab/Alert';

type verticalPosition = 'top' | 'bottom';
type horizontalPosition = 'right' | 'center' | 'left';

type Props = {
  anchorOrigin?: {
    vertical?: verticalPosition,
    horizontal?: horizontalPosition,
  },
  isShowing: boolean,
  message: string,
  severity: Severity,
  onClose?: void => void,
  onClick?: void => void,
};

export default memo<Props>(function Alert({
  anchorOrigin,
  isShowing,
  message,
  severity,
  onClose,
  onClick,
}: Props) {
  return (
    <Snackbar
      open={isShowing}
      autoHideDuration={5000}
      onClose={() => onClose && onClose()}
      onClick={() => onClick && onClick()}
      anchorOrigin={
        anchorOrigin
          ? anchorOrigin
          : { vertical: 'bottom', horizontal: 'center' }
      }
    >
      <MuiAlert variant="filled" color={severity}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
});
