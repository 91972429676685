//@flow
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import { result } from './util';
import type { PaginatedResponse } from './_common';

const sevenhellApiVersion = '/_ah/api/userapi/v2';

export const ScopedAccessTokenType = {
  APP_SECURE_SHARE_MOBILE_APP_PERMISSIONS:
    'APP_SECURE_SHARE_MOBILE_APP_PERMISSIONS',
  APP_SECURE_SHARE_FINDING_PERMISSIONS: 'APP_SECURE_SHARE_FINDING_PERMISSIONS',
  APP_SEARCH_SHARE_OPENSCAN_APP_PERMISSIONS:
    'APP_SEARCH_SHARE_OPENSCAN_APP_PERMISSIONS',
};

export type ScopedProperties = {
  can_access_all_apps: boolean,
  can_access_app_ids: Array<string>,
  can_access_scope: ScopedAccessTokenTypeEnum,
};

export type ScopedAccessTokenTypeEnum = $Keys<typeof ScopedAccessTokenType>;
export type ScopedAccessToken = {
  access_granted_by: string,
  app_secure_access_properties?: {
    ...ScopedProperties,
    can_access_app_secure_security_finding_id: null | string,
  },
  app_search_access_properties?: ScopedProperties,
  access_token: string,
  date_created: string,
  date_expires: string,
  date_updated: string,
  id: string,
};

type ListResponse = PaginatedResponse<{
  +scoped_access_tokens: $ReadOnlyArray<ScopedAccessToken>,
}>;

export async function list(): Promise<ListResponse> {
  return fetch(
    `${config.sevenhellAPI}${sevenhellApiVersion}/scoped_access_tokens`,
  )
    .then(parse)
    .then(result);
}

export async function remove(scoped_access_token_id: string): Promise<boolean> {
  return fetch(
    `${config.sevenhellAPI}${sevenhellApiVersion}/scoped_access_tokens/${scoped_access_token_id}`,
    {
      method: 'DELETE',
    },
  )
    .then(parse)
    .then(r => r.status === 204);
}

export async function create_for_mobile_app_id(
  mobile_app_id: string,
  validity_duration: number,
): Promise<ScopedAccessToken> {
  return fetch(
    `${config.sevenhellAPI}${sevenhellApiVersion}/mobile_apps/${mobile_app_id}/scoped_access_tokens`,
    {
      method: 'POST',
      body: JSON.stringify({
        mobile_app_id,
        validity_duration,
      }),
    },
  )
    .then(parse)
    .then(result);
}

export async function create_for_finding_id(
  finding_id: string,
  validity_duration: number,
): Promise<ScopedAccessToken> {
  return fetch(
    `${config.sevenhellAPI}${sevenhellApiVersion}/security_findings/${finding_id}/scoped_access_tokens`,
    {
      method: 'POST',
      body: JSON.stringify({
        validity_duration,
      }),
    },
  )
    .then(parse)
    .then(result);
}

export async function create_for_open_scan_mobile_app_id(
  mobile_app_id: string,
  validity_duration: number,
): Promise<ScopedAccessToken> {
  return fetch(
    `${config.sevenhellAPI}${sevenhellApiVersion}/openscan/mobile_apps/${mobile_app_id}/scoped_access_tokens`,
    {
      method: 'POST',
      body: JSON.stringify({
        validity_duration,
      }),
    },
  )
    .then(parse)
    .then(result);
}
