// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';

if (typeof config.horizonAPI !== 'string') {
  throw new Error('horizonAPI is required but not set in config');
}

const api = config.horizonAPI;
const version = '/public/v1';

export type CloudResourceType = {|
  enum: string,
  icon_url: string,
  name: string,
  category_enum: string,
  category_name: string,
|};

export type CloudResourceTypesList = $ReadOnlyArray<CloudResourceType>;

export type CloudResourceTypesResponse = {|
  cloud_resource_types: CloudResourceTypesList,
|};

export async function list(): FetchResponse<CloudResourceTypesResponse> {
  return fetch(`${api}/${version}/cloud_resource_types`).then(parse);
}
