// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';
import { type UsersList, type Authenticator } from './types';

if (typeof config.horizonAPI !== 'string') {
  throw new Error('horizonAPI is required but not set in config');
}

const api = config.horizonAPI;
const version = '/public/v1';

export type AzureAuthenticator = {|
  ...Authenticator,
  tenant_id: string,
  client_id: string,
|};

export type AzureAuthenticatorsList = $ReadOnlyArray<AzureAuthenticator>;

export type AzureAuthenticatorsListResponse = {|
  azure_authenticators: AzureAuthenticatorsList,
  users: UsersList,
|};

export type AzureAuthenticatorsCreateRequestParams = {
  tenant_id: string,
  client_id: string,
  client_secret: string,
  ...
};

export type AzureAuthenticatorCreateResponse = AzureAuthenticator;

export async function list(): FetchResponse<AzureAuthenticatorsListResponse> {
  return fetch(`${api}/${version}/azure_authenticators`).then(parse);
}

export async function create(
  params: AzureAuthenticatorsCreateRequestParams,
): FetchResponse<AzureAuthenticatorCreateResponse> {
  return fetch(`${api}/${version}/azure_authenticators`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
