// @flow
import React, { type ComponentType } from 'react';
import NotFoundPage from './NotFoundPage';

/** Given component, will guarantee that props id is given, otherwise render NotFoundPage.
 * Reason is that the browser cannot guarantee that the parameter id will be given, so we guarantee it here
 */
export const GuaranteeId = <P: { ... }>(props: {
  ...P,
  +id?: ?string,
  +component: ComponentType<P>,
  ...
}) => {
  const { component: Component, id, ...compProps } = props;
  if (typeof id !== 'string') return <NotFoundPage />;

  return <Component {...compProps} id={id} />;
};
