// @flow
import { type PolicyRuleTypeList } from '@dt/horizon-api';

// We alphabetize the list of checks by alphabetical order, but when these ids
// are present they should be sorted to first and second. Ask Alban/HD.
const policyRuleTypeIdFirst = '3ce37867-3680-5094-86bc-d4e7eb3265ef'; // API operation has authentication disabled
const policyRuleTypeIdSecond = '67599de3-2914-54af-a177-7ee9a4241ce0'; // API operation is declared unauthenticated

export const sortPoliciesRulesType = (
  policy_rule_types: PolicyRuleTypeList,
): PolicyRuleTypeList => {
  const alphabeticalOrderGroupRules = policy_rule_types
    .filter(
      rule_type =>
        rule_type.id !== policyRuleTypeIdFirst &&
        rule_type.id !== policyRuleTypeIdSecond,
    )
    .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));

  const orderedGroupRules = [
    policy_rule_types.find(rule_type => rule_type.id === policyRuleTypeIdFirst),
    policy_rule_types.find(
      rule_type => rule_type.id === policyRuleTypeIdSecond,
    ),
    ...alphabeticalOrderGroupRules,
  ].filter(Boolean);

  return orderedGroupRules;
};
