// @flow
import config from '@dt/config';
import qs from 'query-string';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';
import {
  type APIOperationScanTracesList,
  type APIOperationsList,
  type DomainNameList,
  type EventsList,
  type NetworkServicesList,
  type OpenAPIDefinitionsList,
  type PaginatedResponse,
  type PolicyRuleList,
  type PolicyRuleTypeList,
  type PolicyViolationList,
  type RestfulAPIsList,
} from './types';

if (typeof config.horizonAPI !== 'string') {
  throw new Error('Client.horizonAPI is required but not set in config');
}

const api = config.horizonAPI;
const version = 'public/v1';

export type RESTfulAPIResponse = {|
  +restful_apis: RestfulAPIsList,
  +openapi_definitions: OpenAPIDefinitionsList,

  +network_services: NetworkServicesList,
  +domain_names: DomainNameList,

  +policy_violations: PolicyViolationList,
  +policy_rules: PolicyRuleList,
  +policy_rule_types: PolicyRuleTypeList,
|};

export async function list(params: {
  ...,
}): FetchResponse<PaginatedResponse<RESTfulAPIResponse>> {
  return fetch(`${api}/${version}/restful_apis?${qs.stringify(params)}`).then(
    parse,
  );
}

export type RESTfulAPIResponseDetails = {|
  ...RESTfulAPIResponse,

  +events: EventsList,
|};

export async function details(
  id: string,
): FetchResponse<RESTfulAPIResponseDetails> {
  return fetch(`${api}/${version}/restful_apis/${id}`).then(parse);
}

/** API Operations **/

export type APIOperationAPIResponse = {|
  +api_operations: APIOperationsList,
  +api_operation_scan_traces: APIOperationScanTracesList,
|};

export async function api_operation_details(
  restful_api_id: string,
  api_operation_id: string,
): FetchResponse<APIOperationAPIResponse> {
  return fetch(
    `${api}/${version}/restful_apis/${restful_api_id}/api_operations/${api_operation_id}`,
  ).then(parse);
}
