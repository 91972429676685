//@flow
import React from 'react';

const categoryMetadata = {
  priority: {
    title: 'Unsubscribed Priority Alerts',
    description: (
      <p>
        Priority Alerts are a combination of P1 security issues (vulnerabilities
        that allow a<strong> remote </strong> attacker to exploit private data)
        or security flaws that will cause the app to be rejected by the App
        Store or Google Play.
      </p>
    ),
    categoryType: 'ALL',
    breakdownType: 'PARTIAL',
  },
  security: {
    title: 'Unsubscribed Security P1s',
    description: (
      <p>
        Priority 1 (P1) issues are security vulnerabilities that allow private
        data to be exploited
        <strong> remotely </strong> by attackers.
      </p>
    ),
    categoryType: 'P1',
    breakdownType: 'NONE',
  },
  google: {
    title: 'Unsubscribed Google Play Blockers',
    description: (
      <p>
        Google Play Blockers are security flaws that will cause the app to be
        rejected by the Play Store.
      </p>
    ),
    categoryType: 'GOOGLE_BLOCKER',
    breakdownType: 'NONE',
  },
  apple: {
    title: 'Unsubscribed App Store Blockers',
    description: (
      <p>
        App Store Blockers are security flaws that will cause the app to be
        rejected by the App Store.
      </p>
    ),
    categoryType: 'APPLE_BLOCKER',
    breakdownType: 'NONE',
  },
  insight: {
    title: 'Unsubscribed OSS/SDK Issues',
    description: (
      <p>
        To ensure customers have full visibility on their open source libraries
        and commercial SDKs, Data Theorem provides the following metrics
        free-of-charge for every SDK within your ecosystem.
      </p>
    ),
    categoryType: 'INSIGHT',
    breakdownType: 'NONE',
  },
  compliance: {
    title: 'Unsubscribed Regulatory Compliance Issues',
    description: (
      <p>
        Regulatory Compliance issues are items that impact an App’s ability to
        comply with security and privacy-focused laws, policies, and standards.
        (GDPR, FTC, PCI, HIPAA, OWASP, etc.)
      </p>
    ),
    categoryType: 'COMPLIANCE',
    breakdownType: 'NONE',
  },
  P1_ALERTS: {
    title: 'Unsubscribed Security P1 Issues',
    description: (
      <p>
        Priority 1 (P1) issues are security vulnerabilities that allow private
        data to be exploited
        <strong> remotely </strong> by attackers.
      </p>
    ),
    categoryType: 'ALL',
    breakdownType: 'NONE',
  },
  STATIC: {
    title: 'Unsubscribed Baseline Issues',
    description: (
      <p>
        Baseline issues are security vulnerabilities identified in the
        app&apos;s code (Objective-C, Swift, Java), 3rd-party SDKs, and open
        source libraries. Additionally, security flaws that will cause the app
        to be rejected by the App Store or Google Play will also be shown.
      </p>
    ),
    categoryType: 'ALL',
    breakdownType: 'PARTIAL',
  },
  DYNAMIC: {
    title: 'Unsubscribed Enterprise Issues',
    description: (
      <p>
        Enterprise issues are identified by authenticated dynamic analysis,
        where every app screen, data flow, and server side mobile API is tested.
        The scan also includes compliance requirements from the FTC, HIPAA,
        GDPR, and PCI as well as a review of every server-side endpoint to
        ensure the app is not communicating with known malicious IPs or domains.
      </p>
    ),
    categoryType: 'ALL',
    breakdownType: 'PARTIAL',
  },
  all: {
    title: 'All Unsubscribed Issues',
    description: (
      <p>
        To ensure customers have full visibility on their entire mobile app
        security posture, Data Theorem provides the following metrics
        free-of-charge for every unsubscribed app within your ecosystem.
      </p>
    ),
    categoryType: 'ALL',
    breakdownType: 'FULL',
  },
};

export function getShadowScanTitle(category: string) {
  return `${getMetadataOr(category, 'title') || 'Unsubscribed Issues'}`;
}

export function getMetadataOr(
  category: string,
  field: string,
  defaultValue: ?string = null,
) {
  const metadata = categoryMetadata[category];

  if (!metadata) {
    return defaultValue;
  }

  return metadata[field] || defaultValue;
}
