//@flow
import gql from 'graphql-tag';

const create_for_policy_violation = gql`
  mutation SharedLinksCreateForPolicyViolationMutation(
    $id: ID!
    $body: SharedLinksCreateForPolicyViolationParams!
  ) {
    shared_links_for_policy_violation_create(id: $id, body: $body) {
      shared_resource_type
      shared_resource_id
      access_token
    }
  }
`;

export default {
  create_for_policy_violation,
};
