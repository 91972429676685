//@flow
import React from 'react';
import { default as ReactDropzone } from 'react-dropzone';
import UploadIcon from '@material-ui/icons/CloudUpload';

import type { Node } from 'react';

type OwnProps = {|
  +onDropFile: (file: File) => void,
  +ariaLabel?: string,
  +dragActiveText: string,
  +children: Node,
|};

type ForwardRefProps = {|
  innerRef:
    | { current: null | ReactDropzone, ... }
    | ((null | ReactDropzone) => mixed),
|};

type Props = {
  ...OwnProps,
  ...ForwardRefProps,
  ...
};

const DropzoneComponent = function Dropzone({
  children,
  dragActiveText,
  ariaLabel,
  innerRef,
  onDropFile,
}: Props) {
  const handleOnDrop = (acceptedFiles: $ReadOnlyArray<File>) => {
    onDropFile(acceptedFiles[0]);
  };

  return (
    <ReactDropzone
      ref={innerRef}
      multiple={false}
      name="file"
      onDrop={handleOnDrop}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div style={{ width: '100%', cursor: 'pointer' }} {...getRootProps()}>
          <input aria-label={ariaLabel} {...getInputProps()} />
          {isDragActive && (
            <div
              style={{
                zIndex: 10000,
                boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,0.6)',
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
              }}
            >
              <div
                style={{
                  width: '40%',
                  height: '40%',
                  margin: 'auto',
                }}
              >
                <div
                  style={{
                    fontSize: 30,
                    color: '#fff',
                  }}
                >
                  <UploadIcon
                    style={{
                      width: '100px !important',
                      height: '100px !important',
                      color: '#fff !important',
                      verticalAlign: 'middle',
                      marginRight: 10,
                    }}
                  />
                  {dragActiveText}
                </div>
              </div>
            </div>
          )}
          {children}
        </div>
      )}
    </ReactDropzone>
  );
};

export const Dropzone = React.forwardRef<OwnProps, ReactDropzone>(
  (props, ref) => <DropzoneComponent innerRef={ref} {...props} />,
);
