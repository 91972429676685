// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';
import { type UsersList, type Authenticator } from './types';

if (typeof config.horizonAPI !== 'string') {
  throw new Error('horizonAPI is required but not set in config');
}

const api = config.horizonAPI;
const version = '/public/v1';

export type GcpAuthenticator = {|
  ...Authenticator,
  organization_id: string,
  client_email: string,
|};

export type GcpAuthenticatorsList = $ReadOnlyArray<GcpAuthenticator>;

export type GcpAuthenticatorsListResponse = {|
  gcp_authenticators: GcpAuthenticatorsList,
  users: UsersList,
|};

export type GcpAuthenticatorsCreateRequestParams = {
  organization_id: string,
  service_account: JSON,
  ...
};

export type GcpAuthenticatorsCreateResponse = GcpAuthenticator;

export async function list(): FetchResponse<GcpAuthenticatorsListResponse> {
  return fetch(`${api}/${version}/gcp_authenticators`).then(parse);
}

export async function create(
  params: GcpAuthenticatorsCreateRequestParams,
): FetchResponse<GcpAuthenticatorsCreateResponse> {
  return fetch(`${api}/${version}/gcp_authenticators`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
