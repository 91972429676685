//@flow

const category = {
  DATA_AT_REST_EXPOSURE: 'DATA_AT_REST_EXPOSURE',
  DATA_IN_TRANSIT_EXPOSURE: 'DATA_IN_TRANSIT_EXPOSURE',
  DATA_LOSS_TO_HACKERS: 'DATA_LOSS_TO_HACKERS',
  DATA_EXPOSURE_TO_THIRD_PARTY_APPS: 'DATA_EXPOSURE_TO_THIRD_PARTY_APPS',
  UNAUTHORIZED_DATA_COLLECTION: 'UNAUTHORIZED_DATA_COLLECTION',
};

export default category;

export type SecurityTemplateCategoryEnum = $Keys<typeof category>;
