// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';

if (typeof config.horizonAPI !== 'string') {
  throw new Error('horizonAPI is required but not set in config');
}

const api = config.horizonAPI;
const version = '/public/v1';

export type CertificateTransparencyDomains = {|
  domain_names: $ReadOnlyArray<string>,
|};

export async function get(): FetchResponse<CertificateTransparencyDomains> {
  return fetch(`${api}/${version}/certificate_transparency_domains`).then(
    parse,
  );
}
