//@flow
import React from 'react';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { palette, fontSizes } from '@dt/theme';
import Add from '@material-ui/icons/Add';
import Text from './Text';

type Comment = {
  +id: ?string,
  +author_email: ?string,
  +date: ?string,
  +is_internal_comment: boolean,
  +text: ?string,
  ...
};

type Props = {
  +sortedComments: $ReadOnlyArray<Comment>,
  +onOpenWorkflow: () => void,
};

/*
 * DO NOT use directly.
 *
 * Header details for "Advice/Notes" used by the following components:
 *
 * - PolicyViolationsCommentsSection
 * - PolicyViolationsCommentsSectionCard
 */
const PolicyViolationsCommentsSectionHeaderComponent = function PolicyViolationsCommentsSectionHeader({
  sortedComments,
  onOpenWorkflow,
}: Props) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Text
        variant="titleXS"
        color={palette.gray20}
        style={{ margin: 0, textTransform: 'uppercase' }}
      >
        Advice/Notes ({sortedComments.length})
      </Text>

      <Button
        style={{
          marginLeft: 16,
          height: 30,
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 12,
          paddingRight: 12,
          color: palette.gray20,
          backgroundColor: palette.gray45,
          minWidth: 'unset',
        }}
        onClick={onOpenWorkflow}
      >
        <Add style={{ fontSize: fontSizes.large, marginBottom: 2 }} />
        <span style={{ marginLeft: 8, whiteSpace: 'pre' }}>
          <Text variant="button" color="inherit">
            Appsec Advice/Internal Notes
          </Text>
        </span>
      </Button>
    </Box>
  );
};

export const PolicyViolationsCommentsSectionHeader = PolicyViolationsCommentsSectionHeaderComponent;
