// @flow
import React, { type Node } from 'react';
import { palette } from '@dt/theme';
import { ExtLink } from '@dt/material-components';
import { Typography, CircularProgress } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ArrowForward from '@material-ui/icons/ArrowForward';

type RoadmapActionItemProps = {|
  children: Node,
  linkTo: string,
  isLoading: boolean,
  isComplete: boolean,
|};

const iconSize = 24;

const RoadmapActionItem = ({
  children,
  linkTo,
  isComplete,
  isLoading,
}: RoadmapActionItemProps) => {
  const style = {
    display: 'flex',
    maxWidth: 800,
    color: isComplete ? palette.gray20 : palette.blue20,
    textDecoration: isComplete ? 'none' : 'underline',
    padding: '10px 16px 16px',
    alignItems: 'start',
  };

  return (
    <ExtLink target="_self" to={linkTo} style={style}>
      <div style={{ display: 'flex', marginRight: 8 }}>
        {isLoading ? (
          <CircularProgress size={iconSize} />
        ) : isComplete ? (
          <DoneIcon
            style={{
              color: palette.green,
              width: iconSize,
            }}
          />
        ) : (
          <ArrowForward
            style={{
              color: palette.blue,
              width: iconSize,
            }}
          />
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="body2"
          style={{ color: 'inherit' }}
          gutterBottom={isComplete}
        >
          {isComplete && (
            <span
              variant="body2"
              style={{
                color: palette.green20,

                display: 'inline',
              }}
            >
              [Complete]{' '}
            </span>
          )}
          {children}
        </Typography>
      </div>
    </ExtLink>
  );
};

export default RoadmapActionItem;
