// @flow
import React, { memo } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type Props = {
  feature: string,
  onClose: () => void,
};

function UpgradeDialog({ feature, onClose }: Props) {
  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle disableTypography>
        <Typography variant="h5">
          Please upgrade your subscription to access this feature
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <strong>{`${feature}`}</strong> is a premium feature that requires a
          subscription upgrade. Please contact customer support to upgrade your
          subscription.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          href="mailto:support@datatheorem.com"
        >
          Contact Support
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo<Props>(UpgradeDialog);
