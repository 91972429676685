// @flow
import React, { useState, type Node } from 'react';
import { IconButton, Collapse, Divider } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

type Props = {
  summary: Node,
  children: Node,
  defaultExpanded?: boolean,
};

const PageSectionCollapse = ({ summary, children, defaultExpanded }: Props) => {
  const [panelClosed, setPanelClosed] = useState(
    defaultExpanded ? defaultExpanded : false,
  );

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          paddingBottom: 6,
          minHeight: 24,
        }}
        onClick={() => setPanelClosed(!panelClosed)}
      >
        {summary}
        <IconButton style={{ alignSelf: 'flex-end' }}>
          {panelClosed ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </div>
      <Divider style={{ marginBottom: panelClosed ? 6 : 0 }} />
      <Collapse in={panelClosed}>
        <div style={{ padding: '16px 0' }}>{children}</div>
      </Collapse>
    </div>
  );
};

export default PageSectionCollapse;
