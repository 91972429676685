//@flow
import React, { memo, type Node } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import InquireButton from './InquireButton';

type Props = {|
  open: boolean,
  onRequestClose: () => mixed,
  onClickInquire: (e: SyntheticMouseEvent<HTMLElement>) => void,
  children?: ?Node,
|};

export default memo<Props>(function PreviewDialog(props) {
  const { children, open, onRequestClose, onClickInquire } = props;

  return (
    <Dialog open={open} onClose={onRequestClose}>
      <DialogTitle>Preview Unsubscribed Issues</DialogTitle>
      <DialogContent>
        <div style={{ position: 'absolute', top: 20, right: 20 }}>
          <InquireButton onClickInquire={onClickInquire} />
        </div>

        <div
          style={{
            overflowY: 'auto',
            maxHeight: 'inherit',
            paddingRight: 10,
          }}
        >
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
});
