//@flow
import React, { memo } from 'react';
import * as scoped_access_tokens from '@dt/user-api/scoped_access_tokens';
import Banner from './Banner';
import { SharedLinksButtonCommon } from './SharedLinksButtonCommon';

type Props = {|
  +mobile_application_id: string,
  +security_finding_id: string,
|};

/*
 * NOTE: Use the `SharedLinksButton` facade component.
 *
 * Shared links button used to share a mobile application security finding.
 *
 * @param mobile_application_id - Used with the api when creating a shared link
 * @param security_finding_id - Used with the api when creating a shared link
 *
 * @example
 *     <SharedLinksButtonSecurityFinding
 *       mobile_application_id={mobile_application.id}
 *       security_finding={security_finding.id} />
 */
const SharedLinksButtonSecurityFindingComponent = function SharedLinksButtonSecurityFinding({
  mobile_application_id,
  security_finding_id,
}: Props) {
  /*
   * Handle creating a shared link for security finding.
   */
  const handleOnCreate = async validity_duration => {
    // Create shared link.
    const response = await scoped_access_tokens.create_for_finding_id(
      security_finding_id,
      validity_duration,
    );

    // Success, let shared links button render page url.
    return `${window.location.origin}/mobile/share/${response.access_token}/app/${mobile_application_id}/single-finding/${security_finding_id}`;
  };

  return (
    <SharedLinksButtonCommon
      manageShareLink="/shared-links"
      title={`Create a Secure Link to Finding #${security_finding_id}`}
      onCreate={handleOnCreate}
    >
      <Banner status="warning" inline icon={<></>}>
        The secure link will allow temporary access only to this issue.
      </Banner>
    </SharedLinksButtonCommon>
  );
};

export const SharedLinksButtonSecurityFinding = memo<Props>(
  SharedLinksButtonSecurityFindingComponent,
);
