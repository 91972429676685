// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';
import { type PaginatedResponse } from './types';

if (typeof config.horizonAPI !== 'string') {
  throw new Error('horizonAPI is required but not set in config');
}

const api = config.horizonAPI;
const version = '/public/v1';

export const AlertsIntegrationsServiceEnum = {
  SLACK: 'SLACK',
  MICROSOFT_TEAMS: 'MICROSOFT_TEAMS',
};

export type AlertsIntegrationsServiceEnumType = $Keys<
  typeof AlertsIntegrationsServiceEnum,
>;

export type AlertsIntegrations = {|
  id: string,
  created_by_user_id: string,
  name: string,
  date_created: string,
  service_enum: AlertsIntegrationsServiceEnumType,
  webhook_url: string,
|};

export type AlertsIntegrationsList = $ReadOnlyArray<AlertsIntegrations>;

type AlertsIntegrationsResponse = {|
  alerts_integrations: AlertsIntegrationsList,
|};

export async function list(): FetchResponse<
  PaginatedResponse<AlertsIntegrationsResponse>,
> {
  return fetch(`${api}/${version}/alerts_integrations`).then(parse);
}

export type AlertsIntegrationsCreateParams = {
  name: string,
  service_enum: AlertsIntegrationsServiceEnumType,
  webhook_url: string,
  ...
};

export async function create(
  params: AlertsIntegrationsCreateParams,
): FetchResponse<AlertsIntegrationsResponse> {
  return fetch(`${api}/${version}/alerts_integrations`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}

export async function details(
  id: string,
): FetchResponse<AlertsIntegrationsResponse> {
  return fetch(`${api}/${version}/alerts_integrations/${id}`).then(parse);
}

export type AlertsIntegrationsPatchParams = {
  name?: string,
  webhook_url?: string,
};

export async function patch(
  id: string,
  params: AlertsIntegrationsPatchParams,
): FetchResponse<AlertsIntegrationsResponse> {
  return fetch(`${api}/${version}/alerts_integrations/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(params),
  }).then(parse);
}

export async function remove(id: string): Promise<boolean> {
  return fetch(`${api}/${version}/alerts_integrations/${id}`, {
    method: 'DELETE',
  }).then(res => res.ok);
}
