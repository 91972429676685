// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { reminderContents } from '../contents';
import { container, modal, paper, root, actions, button } from '../style';
import type { ReminderPropsType } from '../types';
import { useDispatch } from 'react-redux';
import {
  notifyBillingContactAction,
  redirectToPurchaseQuoteAction,
  redirectToPayNowAction,
  openQouteUrlAction,
  openUploadPoComponentAction,
} from '../redux/actions';

const useStyles = makeStyles({
  container,
  modal,
  paper,
  root,
  actions,
  button,
});

type Props = ReminderPropsType;

const PopupReminder = props => {
  const {
    reminder,
    notifyBilling,
    payNow,
    quoteUrl,
    purchaseQuote,
    poUpload,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.root}>
          <Grid container className={classes.paper} spacing={1}>
            {reminder.shouldShow && (
              <Grid item xs={12}>
                <Typography variant="h5" component="h5">
                  {reminder.title}
                </Typography>
                {reminder.description}
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                {notifyBilling.shouldShow && (
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Button
                      color="primary"
                      aria-label={reminderContents.buttons['notifyBilling']}
                      variant="contained"
                      onClick={() => {
                        dispatch(notifyBillingContactAction());
                      }}
                    >
                      {reminderContents.buttons['notifyBilling']}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={6}>
                      {quoteUrl.shouldShow && (
                        <Button
                          color="primary"
                          aria-label={reminderContents.quote_url}
                          variant="contained"
                          onClick={() => {
                            dispatch(openQouteUrlAction());
                          }}
                        >
                          {reminderContents.quote_url}
                        </Button>
                      )}
                      {purchaseQuote.shouldShow && (
                        <Button
                          color="primary"
                          aria-label={''}
                          variant="contained"
                          onClick={() => {
                            dispatch(redirectToPurchaseQuoteAction());
                          }}
                        >
                          GET PRICING
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container direction="row" justify="flex-end">
                        {payNow.shouldShow && (
                          <Button
                            color="primary"
                            aria-label={'Pay by Credit Card'}
                            variant="contained"
                            onClick={() => {
                              dispatch(redirectToPayNowAction());
                            }}
                          >
                            {reminderContents.buttons['payByCard']}
                            <span style={{ fontSize: 9, paddingLeft: 8 }}>
                              Credit Card / Wire / Bank
                            </span>
                          </Button>
                        )}
                        {poUpload.shouldShow && (
                          <Button
                            color="primary"
                            aria-label={'Upload PO'}
                            variant="contained"
                            onClick={() => {
                              dispatch(openUploadPoComponentAction());
                            }}
                          >
                            {reminderContents.buttons['uploadPo']}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo<Props>(PopupReminder);
