//@flow
import React, { memo, type Node } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { palette, fontFamilies } from '@dt/theme';
import { type Properties } from 'csstype';

const variantToStyle: { [Variant]: Properties<>, ... } = {
  titleXL: {
    color: palette.gray20,
    fontFamily: fontFamilies.primary,
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '1px',
  },
  titleL: {
    color: palette.gray20,
    fontFamily: fontFamilies.primary,
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '30px',
    letterSpacing: '0.5px',
  },
  titleM: {
    color: palette.gray20,
    fontFamily: fontFamilies.primary,
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
  },
  titleS: {
    color: palette.gray20,
    fontFamily: fontFamilies.primary,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
  },
  titleXS: {
    color: palette.gray20,
    fontFamily: fontFamilies.primary,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
  },
  body: {
    color: palette.gray20,
    fontFamily: fontFamilies.primary,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  bodyS: {
    color: palette.gray30,
    fontFamily: fontFamilies.primary,
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '26px',
  },
  caption: {
    color: palette.gray30,
    fontFamily: fontFamilies.primary,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
  },
  label: {
    color: palette.gray20,
    fontFamily: fontFamilies.primary,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
  },
  labelL: {
    color: palette.gray20,
    fontFamily: fontFamilies.primary,
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '16px',
  },
  button: {
    color: palette.gray20,
    fontFamily: fontFamilies.primary,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.5',
    textTransform: 'uppercase',
  },
  link: {
    color: palette.blue20,
    fontFamily: fontFamilies.primary,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    textDecoration: 'underline',
  },
  helperText: {
    color: palette.gray30,
    fontFamily: fontFamilies.primary,
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '14px',
  },
  chip: {
    color: palette.gray20,
    fontFamily: fontFamilies.primary,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '11px',
  },
  numberXL: {
    color: palette.gray20,
    fontFamily: fontFamilies.primary,
    fontWeight: 500,
    fontSize: '40px',
    lineHeight: '48px',
    letterSpacing: '1px',
  },
  code: {
    color: palette.gray20,
    fontFamily: fontFamilies.mono,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
  },
};

const variantToElement: { [Variant]: ComponentType, ... } = {
  titleXL: 'h1',
  titleL: 'h2',
  titleM: 'h3',
  titleS: 'h4',
  titleXS: 'h5',
  body: 'p',
  bodyS: 'p',
  caption: 'span',
  label: 'span',
  labelL: 'span',
  link: 'span',
  button: 'span',
  helperText: 'span',
  chip: 'span',
  numberXL: 'span',
  code: 'code',
};

type ComponentType =
  | 'a'
  | 'code'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'p'
  | 'span'
  | 'div'
  | 'pre'
  | 'li';

type Variant =
  | 'titleXL'
  | 'titleL'
  | 'titleM'
  | 'titleS'
  | 'titleXS'
  | 'body'
  | 'bodyS'
  | 'caption'
  | 'label'
  | 'labelL'
  | 'link'
  | 'button'
  | 'helperText'
  | 'chip'
  | 'numberXL'
  | 'code';

let useStyles = makeStyles({
  // We default to `styles` defined for every `variant`
  // but if the `color` is given, we override it and if
  // entire `style` is given we override it.
  text: props => ({
    ...variantToStyle[props.variant],
    ...(props.color && { color: props.color }),
    ...(props.style && props.style),
  }),
  noWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

type Props = {
  children: ?Node,
  variant?: Variant,
  component?: ComponentType,
  label?: string,
  style?: Properties<>,
  color?: string,
  className?: string,
  noWrap?: boolean,
  onClick?: () => void,
};

function Text({
  variant = 'body',
  component,
  style = {},
  color,
  className = '',
  children,
  label,
  noWrap,
  onClick,
}: Props) {
  let css = useStyles({ variant, color, style });
  let Comp = component || variantToElement[variant];

  let classes = [css.text, className && className, noWrap && css.noWrap];

  return (
    <Comp
      aria-label={label}
      className={classes.join(' ').toString()}
      onClick={onClick}
    >
      {children}
    </Comp>
  );
}

export default memo<Props>(Text);
