//@flow
import { type Node, useEffect, useCallback, useRef, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { paginationMountedAction, paginationLoadMoreAction } from './actions';
import getKey from './getKey';
import { createSelectorForPaginationInfo } from './selectors';

type Props = {
  type: string,
  params?: { +[string]: void | string, ... },
  totalCount: number,
  children: ({
    loadedInitial: boolean,
    loading: boolean,
    loadMore: mixed => Promise<void>,
    ...
  }) => Node,
};

export default memo<Props>(({ children, params, type, totalCount }) => {
  const previousKey = useRef();
  const key = getKey(params || {});

  const { loading, loadedInitial, complete } = useSelector(
    createSelectorForPaginationInfo(type, params || {}),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (previousKey.current !== key) {
      previousKey.current = key;
      dispatch(paginationMountedAction(type, params || {}));
    }
  }, [dispatch, params, type, key]);

  const resolvers = useRef([]);
  const previousLoading = useRef(loading);

  const loadMore = useCallback(
    options => {
      return new Promise<void>(resolve => {
        resolvers.current.push({ resolve, options, isResolved: { v: false } });
        dispatch(paginationLoadMoreAction(type, params || {}));
      });
    },
    [dispatch, params, type],
  );

  const previousTotalCount = useRef(null);

  if (
    previousTotalCount.current === totalCount &&
    previousLoading.current &&
    !loading &&
    !complete
  ) {
    previousTotalCount.current = null;
    loadMore({ startIndex: totalCount, stopIndex: totalCount });
  }

  resolvers.current.forEach(({ resolve, options, isResolved }) => {
    if (
      !isResolved.v &&
      options &&
      typeof options === 'object' &&
      typeof options.stopIndex === 'number' &&
      options.stopIndex + 1 <= totalCount
    ) {
      resolve();
      isResolved.v = true;
    }
  });

  if (previousLoading.current === false && loading === true) {
    previousTotalCount.current = totalCount;
  }

  previousLoading.current = loading;

  return children({
    loadMore,
    loadedInitial: !!loadedInitial,
    loading: !!loading,
  });
});
