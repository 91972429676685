// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';
import { type UsersList, type Authenticator } from './types';

if (typeof config.horizonAPI !== 'string') {
  throw new Error('horizonAPI is required but not set in config');
}

const api = config.horizonAPI;
const version = '/public/v1';

export type KongAuthenticator = {|
  ...Authenticator,
  kong_admin_subdomain: string,
|};

export type KongAuthenticatorsList = $ReadOnlyArray<KongAuthenticator>;

export type KongAuthenticatorsListResponse = {|
  kong_authenticators: KongAuthenticatorsList,
  users: UsersList,
|};

export async function list(): FetchResponse<KongAuthenticatorsListResponse> {
  return fetch(`${api}/${version}/kong_authenticators`).then(parse);
}

export type KongAuthenticatorsCreateRequestParams = {
  kong_admin_subdomain: string,
  kong_admin_token: string,
};

export type KongAuthenticatorCreateResponse = KongAuthenticator;

export async function create(
  params: KongAuthenticatorsCreateRequestParams,
): FetchResponse<KongAuthenticatorCreateResponse> {
  return fetch(`${api}/${version}/kong_authenticators`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
