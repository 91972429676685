//@flow
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import { noResult } from './util';

const sevenhellApiVersion = '/_ah/api/userapi/v2';

export const create = (): Promise<void> => {
  return fetch(
    `${config.sevenhellAPI}${sevenhellApiVersion}/renewal_reminders`,
    {
      method: 'POST',
    },
  )
    .then(parse)
    .then(noResult);
};

export default {
  create,
};
