// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';
import type { IPRangeList, IPRangeScanList } from './types';

if (typeof config.horizonAPI !== 'string') {
  throw new Error('horizonAPI is required but not set in config');
}

const api = config.horizonAPI;
const version = '/public/v1';

export type IPRangesCreateResponse =
  | {|
      +ip_ranges: IPRangeList,
      +ip_range_scans: IPRangeScanList,
    |}
  // TODO: The backend should support the noraml error workflow.
  //       Currently the backend doesn't provide an error status code for requesting a local IP address range.
  | {| title: $ReadOnlyArray<string> |};

export async function create(params: {|
  +ip_range: string,
|}): FetchResponse<IPRangesCreateResponse> {
  return fetch(`${api}/${version}/ip_range_scans`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
