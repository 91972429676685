//@flow

const severity = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
};

export default severity;

export type SecurityTemplateSeverityEnum = $Keys<typeof severity>;
