//@flow

const exploitability = {
  DIFFICULT: 'DIFFICULT',
  MODERATE: 'MODERATE',
  EASY: 'EASY',
  UNKNOWN: 'UNKNOWN',
};

export default exploitability;

export type SecurityTemplateExploitabilityEnum = $Keys<typeof exploitability>;
