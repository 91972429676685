//@flow
import React, { memo } from 'react';
import * as scoped_access_tokens from '@dt/user-api/scoped_access_tokens';
import Banner from './Banner';
import { SharedLinksButtonCommon } from './SharedLinksButtonCommon';

type Props = {|
  +mobile_application_id: string,
|};

/*
 * NOTE: Use the `SharedLinksButton` facade component.
 *
 * Shared links button used to share a mobile application.
 *
 * @param mobile_application_id - Used with the api when creating a shared link
 *
 * @example
 *     <SharedLinksButtonMobileApplication mobile_application_id={mobile_application.id} />
 */
const SharedLinksButtonMobileApplicationComponent = function SharedLinksButtonMobileApplication({
  mobile_application_id,
}: Props) {
  /*
   * Handle creating a shared link for mobile applications.
   */
  const handleOnCreate = async validity_duration => {
    // Create shared link.
    const response = await scoped_access_tokens.create_for_mobile_app_id(
      mobile_application_id,
      validity_duration,
    );

    // Success, let shared links button render page url.
    return `${window.location.origin}/mobile/share/${response.access_token}/app/${mobile_application_id}/findings`;
  };

  return (
    <SharedLinksButtonCommon
      manageShareLink="/shared-links"
      title="Create a Secure Link to the App's Findings"
      onCreate={handleOnCreate}
    >
      <Banner status="warning" inline icon={<></>}>
        The secure link will allow temporary access to this application and its
        issues.
      </Banner>
    </SharedLinksButtonCommon>
  );
};

export const SharedLinksButtonMobileApplication = memo<Props>(
  SharedLinksButtonMobileApplicationComponent,
);
