//@flow
import React, { useState, useEffect, memo } from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  TextField,
  Typography,
  CircularProgress,
  Link,
  DialogTitle,
  Dialog,
  Select,
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import copy from '@dt/components/src/copyToClipboard.util';
import Banner from './Banner';

import type { Node } from 'react';

function ConfirmScreen({
  isLoading,
  onCancel,
  onComplete,
}: {
  +isLoading: boolean,
  +onCancel: () => void,
  +onComplete: (expirationInDays: number) => Promise<void>,
}) {
  const [expiration, setExpiration] = useState<number>(7);
  return (
    <>
      <DialogContent component="div">
        <Typography variant="body2" component="div">
          <span id="select-expiration">This link will expire in </span>
          <Select
            labelId="select-expiration"
            value={expiration}
            onChange={e => setExpiration(Number(e.target.value))}
          >
            <MenuItem value={7}>7 days</MenuItem>
            <MenuItem value={15}>15 days</MenuItem>
            <MenuItem value={30}>30 days</MenuItem>
          </Select>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={() => void onComplete(expiration)}
          color="primary"
          variant="contained"
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={15} />}
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
}

function SuccessScreen({
  link,
  manageShareLink,
  onClose,
}: {
  +link: string,
  +manageShareLink?: ?string,
  +onClose: () => void,
}) {
  function onCopy() {
    copy(link);
    onClose();
  }
  return (
    <>
      <DialogContent>
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
            readOnly: true,
          }}
          value={link}
          size="small"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {manageShareLink && (
          <Link to={manageShareLink} underline="none">
            <Button>Manage Links</Button>
          </Link>
        )}
        <Button onClick={onCopy} color="primary" variant="contained">
          Copy Link & Close
        </Button>
      </DialogActions>
    </>
  );
}

type Props = {|
  +title: string,
  +variant?: 'text' | 'outlined' | 'contained',
  +size?: 'small' | 'medium' | 'large',
  +manageShareLink?: ?string,
  +renderButton?: ?(toggleDialog: () => void) => Node,
  +children?: Node,
  +onCreate: (expiration: number) => Promise<string>,
|};

/*
 * NOTE: Use the `SharedLinksButton` facade component.
 *
 * Common implementation details for `SharedLinksButton*` components.
 *
 * @param title - Share dialog custom title
 * @param size - Size of the button
 * @param manageShareLink - Route to the page where shared links are managed
 * @param renderButton - @deprecated Render a different share button. Pass in button props instead like size, variant, etc.
 * @param children - Share dialog custom content
 * @param onCreate - Workflow implemented by `SharedLinksButton*` to create a particular shared link type
 */
const SharedLinksButtonCommonComponent = function SharedLinksButtonCommon({
  title,
  variant,
  size,
  manageShareLink,
  renderButton,
  children,
  onCreate,
}: Props) {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    // This is in the clean up to avoid cleaning up too early
    // while the material-ui animation is still going on.
    return () => {
      if (!isDialogOpen) {
        setLink('');
        setLoading(false);
        setError('');
      }
    };
  }, [isDialogOpen]);

  return (
    <>
      {renderButton ? (
        renderButton(() => setDialogOpen(isDialogOpen => !isDialogOpen))
      ) : (
        <Button
          onClick={() => setDialogOpen(isDialogOpen => !isDialogOpen)}
          variant={variant || 'contained'}
          size={size || 'small'}
          startIcon={<ShareIcon />}
        >
          Secure Share
        </Button>
      )}

      <Dialog
        onClose={() => setDialogOpen(false)}
        aria-labelledby="create-share-link"
        open={isDialogOpen}
      >
        <DialogTitle>
          <span id="create-share-link">{title}</span>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        {error && (
          <DialogContent>
            <Banner status="critical" inline heading="Error">
              {error}
            </Banner>
          </DialogContent>
        )}
        {!link ? (
          <ConfirmScreen
            isLoading={isLoading}
            onCancel={() => setDialogOpen(false)}
            onComplete={async (expirationInDays: number) => {
              setLoading(true);
              try {
                // Create shared link type.
                const link = await onCreate(
                  // validity_duration is in minutes. Multiply by 24 to transform days into hours, then by 60 to transform hours into minutes.
                  expirationInDays * 24 * 60,
                );

                setLoading(false);
                setLink(link);
              } catch (err) {
                setLoading(false);
                setError(
                  'There was an error while sharing this page. Please contact support@datatheorem.com or try again later.',
                );
              }
            }}
          />
        ) : (
          <SuccessScreen
            link={link}
            onClose={() => setDialogOpen(false)}
            manageShareLink={manageShareLink}
          />
        )}
      </Dialog>
    </>
  );
};

export const SharedLinksButtonCommon = memo<Props>(
  SharedLinksButtonCommonComponent,
);
