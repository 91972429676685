// @flow
import React, { type Node } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';

const left_column_base = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  minWidth: 192,
  height: '100%',
  overflowY: 'hidden',
  borderRadius: '4px',
};

const useStyles = makeStyles({
  grid_container: {
    flex: '1 1 auto',
    overflow: 'auto',
  },
  grid_item_left: {
    background: palette.gray50,
    ...left_column_base,
  },
  grid_item_left_skeleton: {
    border: `1px solid ${palette.gray50}`,
    ...left_column_base,
  },
  grid_right_item: {
    height: '100%',
    overflow: 'auto',
  },
});

type Props = {
  skeleton?: ?boolean,
  children: Node,
};

export function TwoColumnLayout({ children }: Props) {
  let css = useStyles();
  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      classes={{ root: css.grid_container }}
    >
      {children}
    </Grid>
  );
}

export function LeftColumn({ children, skeleton }: Props) {
  let css = useStyles();
  return (
    <Grid
      item
      xs={4}
      classes={{
        item: skeleton ? css.grid_item_left_skeleton : css.grid_item_left,
      }}
    >
      {children}
    </Grid>
  );
}

export function RightColumn({ children }: Props) {
  let css = useStyles();
  return (
    <Grid item xs={8} classes={{ root: css.grid_right_item }}>
      {children}
    </Grid>
  );
}
