// @flow
import React, { memo, useEffect } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from '../types';
import BillingComponents from './BillingComponents';
import Grid from '@material-ui/core/Grid';
import {
  createSubscriptionAction,
  notifyBillingContactAction,
  openQouteUrlAction,
  openUploadPoComponentAction,
  redirectToPayNowAction,
  redirectToPurchaseQuoteAction,
} from '../redux/actions';
import { reminderContents } from '../contents';
import { makeStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles/makeStyles';
import style from '../style';
import PopupReminder from './PopupReminder';
import { useAuthorization, useSession } from '@dt/ahura/src/session';
import { ExtLink } from '@dt/material-components';

const useStyles = makeStyles((theme: Theme) => ({
  ...style,
  root: {
    ...style.root,
    background: theme.backgroundColor,
    color: theme.primaryColor,
  },
  link: {
    ...style.link,
    color: theme.primaryColor,
  },
  button: {
    ...style.button,
    color: theme.primaryColor,
  },
  text: {
    '& p': {
      color: theme.primaryColor,
    },
    '& h3': {
      color: theme.primaryColor,
    },
  },
}));

function BillingReminderSnack() {
  const classes = useStyles();

  const { data } = useSession();
  const { isAuthorized } = useAuthorization(data, []);

  const billingInfo = useSelector((state: State) => state.BillingInfo);
  const dispatch = useDispatch();

  let redirectTo;
  if (window && window.location) {
    if (window.location.pathname.indexOf('/mobile') === -1) {
      redirectTo = `/mobile/pricing`;
    }
  }

  useEffect(() => {
    if (data && data.user_account) {
      const { currentUser, accountInfo } = data.user_account;

      dispatch(createSubscriptionAction(accountInfo, currentUser));
    }
  }, [data, dispatch]);

  if (!isAuthorized || (isAuthorized && billingInfo.status === 'unsubscribed'))
    return null;

  const {
    purchaseQuote,
    reminder,
    notifyBilling,
    payNow,
    quoteUrl,
    poUpload,
    period,
  } = billingInfo;

  return (
    <>
      {reminder.shouldShow && (
        <>
          {period.daysToExpire >= 0 ? (
            <Snackbar open={reminder.shouldShow} onClose={() => {}}>
              <Alert
                onClose={() => {}}
                severity={period.daysToExpire <= 60 ? 'error' : 'warning'}
                sx={{ width: '100%' }}
                action={
                  <Grid container direction="row" justify="flex-end">
                    {notifyBilling.shouldShow && (
                      <Button
                        aria-label={'Notify billing contact'}
                        variant="outlined"
                        size={'small'}
                        color={
                          period.daysToExpire <= 60 ? 'secondary' : 'default'
                        }
                        onClick={() => {
                          dispatch(notifyBillingContactAction());
                        }}
                      >
                        {reminderContents.buttons['notifyBilling']}
                      </Button>
                    )}
                    {quoteUrl.shouldShow && (
                      <Button
                        aria-label={reminderContents.quote_url}
                        variant="contained"
                        size={'small'}
                        color={
                          period.daysToExpire <= 60 ? 'secondary' : 'default'
                        }
                        onClick={() => {
                          dispatch(openQouteUrlAction());
                        }}
                      >
                        <div className={classes.button}>
                          <div>VIEW YOUR</div>
                          <div>PO QUOTE HERE</div>
                        </div>
                      </Button>
                    )}
                    {purchaseQuote.shouldShow &&
                      (redirectTo ? (
                        <ExtLink to={redirectTo} target={'_self'}>
                          <Button
                            aria-label={reminderContents.buttons['payByCard']}
                            variant="outlined"
                            size={'small'}
                            color={
                              period.daysToExpire <= 60
                                ? 'secondary'
                                : 'default'
                            }
                          >
                            <div className={classes.button}>
                              <div>GET PRICING</div>
                            </div>
                          </Button>
                        </ExtLink>
                      ) : (
                        <Button
                          aria-label={reminderContents.buttons['payByCard']}
                          variant="outlined"
                          size={'small'}
                          color={
                            period.daysToExpire <= 60 ? 'secondary' : 'default'
                          }
                          onClick={() => {
                            dispatch(redirectToPurchaseQuoteAction());
                          }}
                        >
                          <div className={classes.button}>
                            <div>GET PRICING</div>
                          </div>
                        </Button>
                      ))}
                    {payNow.shouldShow && (
                      <Button
                        aria-label={reminderContents.buttons['payByCard']}
                        variant="outlined"
                        size={'small'}
                        color={
                          period.daysToExpire <= 60 ? 'secondary' : 'default'
                        }
                        onClick={() => {
                          dispatch(redirectToPayNowAction());
                        }}
                      >
                        <div className={classes.button}>
                          <div>{reminderContents.buttons['payByCard']}</div>
                          <span>Credit Card / Wire / Bank</span>
                        </div>
                      </Button>
                    )}
                    {poUpload.shouldShow && (
                      <Button
                        aria-label={'Upload PO'}
                        variant="outlined"
                        size={'small'}
                        color={
                          period.daysToExpire <= 60 ? 'secondary' : 'default'
                        }
                        onClick={() => {
                          dispatch(openUploadPoComponentAction());
                        }}
                      >
                        <div className={classes.button}>
                          {purchaseQuote.shouldShow
                            ? 'Upload PO / Signed Contract'
                            : reminderContents.buttons['uploadPo']}
                        </div>
                      </Button>
                    )}
                  </Grid>
                }
              >
                <AlertTitle>{reminder.title}</AlertTitle>
                {reminder.description}
              </Alert>
            </Snackbar>
          ) : (
            <PopupReminder
              reminder={reminder}
              notifyBilling={notifyBilling}
              payNow={payNow}
              quoteUrl={quoteUrl}
              purchaseQuote={purchaseQuote}
              poUpload={poUpload}
            />
          )}
        </>
      )}

      <BillingComponents />
    </>
  );
}

export default memo<{||}>(BillingReminderSnack);
