//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { receivedFindingsForApp, updateFindingsForApp } from '../actions';
import type {
  OpenscanSecurityFinding,
  OpenscanSecurityFindingList,
} from '@dt/user-api/openscan';

export type OpenscanSecurityFindingsState = OpenscanSecurityFindingList;

export default createReducerCreator<
  OpenscanSecurityFindingsState,
  OpenscanSecurityFinding,
  void,
>({
  initialState: ([]: OpenscanSecurityFindingsState),
  equalityCheck: (a, b) =>
    a.openscan_app_id === b.openscan_app_id && a.title === b.title,
})(
  updateInList([
    updateFindingsForApp.toString(),
    receivedFindingsForApp.toString(),
  ]),
);
