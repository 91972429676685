//@flow
import React, { memo } from 'react';
import { Link } from '@reach/router';
import { Grid, Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { palette, fontFamilies } from '@dt/theme';

/*
 * Calculate the width of text.
 */
const measureTextWidth = (text: string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `16px ${fontFamilies.primary}`;
  // Unable to calculate height here.
  // See https://stackoverflow.com/a/45789011/422312
  const size = context.measureText(text);

  return size.width;
};

type Props = {
  +hasBorder?: boolean,
  +isLoading?: boolean,
  +currentTab: ?string,
  +tabs: $ReadOnlyArray<{
    value: string,
    label: string,
    disabled?: boolean,
    id?: string,
  }>,
  +onChange?: (e: SyntheticEvent<*>, v: any) => void,
};

const useStyles = makeStyles({
  root: props =>
    props.hasBorder
      ? {
          width: '100%',
          borderBottom: '1px solid #9AA5B1',
        }
      : {},
  indicator: {
    backgroundColor: palette.brand30,
  },
  tab_root: {
    minWidth: 72,
    maxWidth: 'inherit',
    color: palette.gray35,
    marginRight: 8,
  },
  tab_selected: { color: palette.brand30 },
});

const Tabs = ({ hasBorder, isLoading, currentTab, tabs, onChange }: Props) => {
  const classes = useStyles({ hasBorder });

  if (isLoading) {
    return (
      <Grid container>
        {tabs.map(({ value, label }) => {
          // Assumes that the provided text will be the same after loading.
          const textWidth = measureTextWidth(label);
          const xPadding = 24;
          const width = textWidth + xPadding;

          return (
            <Skeleton
              key={value}
              variant="rect"
              height={48}
              width={width}
              style={{ display: 'inline-block', marginRight: 8 }}
            />
          );
        })}
      </Grid>
    );
  }

  const tabsChildren = tabs.map(({ value, label, disabled, id }, i) =>
    !onChange ? (
      <MuiTab
        data-testid={id ? id : label}
        value={value}
        label={label}
        aria-label={label}
        classes={{
          selected: classes.tab_selected,
          root: classes.tab_root,
        }}
        disabled={disabled || false}
        to={
          (currentTab ? '../' : '') +
          value.replace(' ', '-').toLocaleLowerCase()
        }
        component={Link}
        key={i}
      />
    ) : (
      <MuiTab
        data-testid={id ? id : label}
        value={value}
        label={label}
        aria-label={label}
        classes={{
          selected: classes.tab_selected,
          root: classes.tab_root,
        }}
        disabled={disabled || false}
        key={i}
      />
    ),
  );

  return (
    <Grid container>
      {!onChange ? (
        <MuiTabs
          value={currentTab || tabs[0].value}
          classes={{ root: classes.root, indicator: classes.indicator }}
        >
          {tabsChildren}
        </MuiTabs>
      ) : (
        <MuiTabs
          value={currentTab || tabs[0].value}
          classes={{ root: classes.root, indicator: classes.indicator }}
          onChange={onChange}
        >
          {tabsChildren}
        </MuiTabs>
      )}
    </Grid>
  );
};

export default memo<Props>(Tabs);
