// @flow
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CheckIcon from '@material-ui/icons/Check';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

import { palette } from '@dt/theme';

type blockType = 'tracked' | 'ok' | 'warning';

function getClassFromCount(count: number | null): blockType {
  return count === null ? 'tracked' : count === 0 ? 'ok' : 'warning';
}

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    padding: '4px 0px',
  },

  col: {
    padding: '4px 12px',
  },

  card: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    minHeight: 28,
    minWidth: 196,
    // maxWidth: 196,
    borderRadius: 3,
    padding: 0,
    fontSize: '0.86rem',
    margin: '8px 4px',
    overflow: 'hidden',
    '&.tracked': {
      backgroundColor: palette.blue50,
    },
    '&.ok': {
      backgroundColor: palette.green50,
    },
    '&.warning': {
      backgroundColor: palette.red50,
    },

    '&.legend': {
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '132px',
      maxWidth: '132px',
    },
  },

  title: {
    padding: '0px 6px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },

  icon: {
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 28,
    maxWidth: 28,
    '&.tracked': {
      color: palette.blue,
    },
    '&.ok': {
      color: palette.green20,
    },
    '&.warning': {
      color: palette.red30,
    },
  },

  truncatedText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  legendBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});

type Props = {|
  title: string,
  // Count == 0 => OK, Count > 0 => Warning, Count == null => Tracked
  count: number | null,
|};

function CoverageBlock(props) {
  const { title, count } = props;
  const classes = useStyles();
  const blockType = getClassFromCount(count);
  return (
    <div className={`${classes.card} ${blockType}`}>
      <div className={`${classes.icon} ${blockType}`}>
        {blockType === 'ok' && <CheckIcon style={{ color: 'current' }} />}
        {blockType === 'tracked' && (
          <TrackChangesIcon style={{ color: 'current' }} />
        )}
        {blockType === 'warning' && (
          <div className={classes.truncatedText}>{count || 0}</div>
        )}
      </div>
      <div className={classes.title}>
        <div className={classes.truncatedText}>{title}</div>
      </div>
    </div>
  );
}

export default memo<Props>(CoverageBlock);

export const CoverageLegend = memo<{||}>(function CoverageLegend() {
  const classes = useStyles();
  return (
    <div className={classes.legendBox}>
      <div className={`${classes.card} legend ok`}>No Issues</div>
      <div className={`${classes.card} legend warning`}>Has Open Issues</div>
      <div className={`${classes.card} legend tracked`}>Tracked Category</div>
    </div>
  );
});
