// @flow
import immer from 'immer';
import { type ActionType } from 'redux-actions';
import { policyRuleTypesReceived } from './actions';
import type { PolicyRuleType } from '@dt/horizon-api';

export type PolicyRuleTypeState = {|
  id: { [string]: void | PolicyRuleType, ... },
  for_group: { [string]: Array<string>, ... },
|};

const initialState: PolicyRuleTypeState = {
  id: {},
  for_group: {},
};

type Actions = ActionType<typeof policyRuleTypesReceived>;

export default immer<PolicyRuleTypeState>((draft, action: Actions) => {
  if (action.type === policyRuleTypesReceived.toString()) {
    for (const policy_rule_type of action.payload) {
      const { id, group } = policy_rule_type;
      draft.id[id] = policy_rule_type;
      draft.for_group[group] = (draft.for_group[group] || []).filter(
        i => i !== id,
      );
      draft.for_group[group].push(id);
    }
  }
}, initialState);
