//@flow
import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import * as scoped_access_tokens from '@dt/user-api/scoped_access_tokens';
import Banner from './Banner';
import { SharedLinksButtonCommon } from './SharedLinksButtonCommon';

type Props = {|
  +openscan_mobile_application_id: string,
  +openscan_mobile_application_name: string,
|};

/*
 * NOTE: Use the `SharedLinksButton` facade component.
 *
 * Shared links button used to share openscan mobile applications.
 *
 * @param openscan_mobile_application_id - Used with the graphql create when creating a shared link
 * @param openscan_mobile_application_name - Used to provide the share dialog some context to the user
 *
 * @example
 *     <SharedLinksButtonOpenscanMobileApplication
 *       openscan_mobile_application_id={mobile_application.id}
 *       openscan_mobile_application_name={mobile_application.name} />
 */
const SharedLinksButtonOpenscanMobileApplicationComponent = function SharedLinksButtonOpenscanMobileApplication({
  openscan_mobile_application_id,
  openscan_mobile_application_name,
}: Props) {
  /*
   * Handle creating a shared link for mobile applications.
   */
  const handleOnCreate = async validity_duration => {
    // Create shared link.
    const scoped_access_token = await scoped_access_tokens.create_for_open_scan_mobile_app_id(
      openscan_mobile_application_id,
      validity_duration,
    );

    // Success, let shared links button render page url.
    return `${window.location.origin}/openscan/share/${scoped_access_token.access_token}/app/${openscan_mobile_application_id}`;
  };

  return (
    <SharedLinksButtonCommon
      renderButton={toggleDialog => (
        <Button
          variant="contained"
          size="small"
          onClick={toggleDialog}
          startIcon={<ShareIcon />}
        >
          Secure Share
        </Button>
      )}
      onCreate={handleOnCreate}
      title={`Create a Secure Link to ${openscan_mobile_application_name}'s Security Results`}
    >
      <Banner status="warning" inline icon={<></>}>
        The secure link will allow temporary access to this application and its
        issues.
      </Banner>
    </SharedLinksButtonCommon>
  );
};

export const SharedLinksButtonOpenscanMobileApplication = memo<Props>(
  SharedLinksButtonOpenscanMobileApplicationComponent,
);
