// @flow
import React, { memo } from 'react';

type Props = {
  size?: string | number,
  values: Array<{ percent: number, color: string }>,
};

const PieChartComponent = function PieChart({ size, values }: Props) {
  let cumulativePercent = 0;

  function getCoordinatesForPercent(percent) {
    const x = Math.cos(2 * Math.PI * percent);
    const y = Math.sin(2 * Math.PI * percent);
    return [x, y];
  }

  return (
    <svg viewBox={'-1 -1 2 2'} height={size || null}>
      {values.map((slice, idx) => {
        // destructuring assignment sets the two variables at once
        const [startX, startY] = getCoordinatesForPercent(cumulativePercent);

        // each slice starts where the last slice ended, so keep a cumulative percent
        cumulativePercent += slice.percent * 0.01;

        const [endX, endY] = getCoordinatesForPercent(cumulativePercent);

        // if the slice is more than 50%, take the large arc (the long way around)
        const largeArcFlag = slice.percent * 0.01 > 0.5 ? 1 : 0;

        return (
          <React.Fragment key={idx}>
            <path
              d={[
                `M ${startX} ${startY}`, // Move
                `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
                `L 0 0`, // Line
              ].join(' ')}
              fill={slice.color}
            />
          </React.Fragment>
        );
      })}
    </svg>
  );
};

export default memo<Props>(PieChartComponent);
