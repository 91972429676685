//@flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import type { CategoryMetadataCount } from '@dt/user-api/mobile_apps';
import { type Insights } from '@dt/user-api/insights';

import CoverageBlock, { CoverageLegend } from './CoverageBlock';

const getMaliciousConnectionsCount = function(insights: Insights): number {
  const communicationInsights = insights.communication_insights;
  return communicationInsights &&
    typeof communicationInsights.communicates_with_blacklisted_ip_addresses !==
      'undefined'
    ? communicationInsights.communicates_with_blacklisted_ip_addresses.length
    : 0;
};

const useStyles = makeStyles({
  coverageBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    padding: '4px 0px',
    overflow: 'hidden',
  },

  coverageColumn: {
    padding: '4px 12px',
    flex: '1 1 25%',
  },
});

type Props = {|
  insights: Insights,
  metadataCount: CategoryMetadataCount,
|};

function ScanCoverageReport(props) {
  const { insights, metadataCount } = props;
  const classes = useStyles();

  if (!metadataCount.OPEN) {
    return null;
  }
  const metadataOpenCount = metadataCount.OPEN;
  const maliciousConnectionsCount = getMaliciousConnectionsCount(insights);

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.coverageBox}>
        <div className={classes.coverageColumn}>
          <CoverageBlock title="C / C++" count={null} key="c" />
          <CoverageBlock title="Objective C" count={null} key="obj-c" />
          <CoverageBlock title="Swift" count={null} key="swift" />
          <CoverageBlock
            title="Dart / Flutter"
            count={null}
            key="dartflutter"
          />
          <CoverageBlock title="Java" count={null} key="java" />
          <CoverageBlock title="Kotlin" count={null} key="kotlin" />
          <CoverageBlock title="React Native" count={null} key="react-native" />
        </div>

        <div className={classes.coverageColumn}>
          <CoverageBlock
            title="APIs Authentication (API Secure)"
            count={null}
            key="rest-api-authentication"
          />
          <CoverageBlock
            title="APIs Authorization (API Secure)"
            count={null}
            key="rest-api-authorization"
          />
          <CoverageBlock
            title="APIs Encryption (API Secure)"
            count={null}
            key="rest-api-encryption"
          />
          <CoverageBlock
            title="Data In-Transit Exposure"
            count={null}
            key="data-in-transit"
          />
          <CoverageBlock
            title="Data At-Rest Exposure"
            count={null}
            key="data-at-rest"
          />
          <CoverageBlock
            title="Mobile Privacy/AppStore Labels"
            count={null}
            key="mobile-privacy"
          />
        </div>

        <div className={classes.coverageColumn}>
          <CoverageBlock
            title="SCA: Open Source Libraries"
            count={metadataOpenCount.SDK}
            key="sdk"
          />
          <CoverageBlock
            title="SCA: 3rd Party Commercial SDKs"
            count={null}
            key="commercial-sdk"
          />
          <CoverageBlock
            title="Compliance (PCI, SOC2, GDPR, etc.)"
            count={metadataOpenCount.COMPLIANCE}
            key="compliance"
          />
          <CoverageBlock
            title="App Store Blockers"
            count={metadataOpenCount.APPLE_BLOCKER}
            key="app-store-blocker"
          />
          <CoverageBlock
            title="Google Play Blockers"
            count={metadataOpenCount.GOOGLE_BLOCKER}
            key="google-play-blocker"
          />
          <CoverageBlock
            title="P1 Security Issues"
            count={metadataOpenCount.P1}
            key="p1"
          />
        </div>

        <div className={classes.coverageColumn}>
          <CoverageBlock
            title="Keys to the Kingdom"
            count={null}
            key="keys-to-the-kingdom"
          />
          <CoverageBlock
            title="Coffee Shop Compromise"
            count={null}
            key="coffee-shop-compromise"
          />
          <CoverageBlock
            title="App Protection"
            count={null}
            key="app-protection"
          />
          <CoverageBlock
            title="Mobile Phishing"
            count={1} // AD: Always show this red as in "all apps do get phished"
            key="app-clone-protection"
          />
          <CoverageBlock
            title="Mobile Protect"
            count={null}
            key="net-protect"
          />
          <CoverageBlock
            title="Mobile Malware"
            count={maliciousConnectionsCount}
            key="malicious-connections"
          />
        </div>
      </div>
      <CoverageLegend />
    </div>
  );
}

export default React.memo<Props>(ScanCoverageReport);
